import React from 'react';
import './App.css';
import Home from './containers/home/Home';
import Recipe from './containers/recipes/Recipe';
import Cook from './containers/cook/Cook';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <header>
        <h1 className="home"><a href="/">Home</a></h1>

        <ul className="nav">
          <li className=""><a href="https://grantharper.org">About</a></li>
        </ul>
      </header>

      <main>
        <div className="container">
          <Switch>
            <Route path="/cook/:id" component={Cook}/>
            <Route path="/recipes/:id" component={Recipe}/>
            <Route path="/" component={Home}/>
          </Switch>
        </div>
      </main>

      <footer>
        <div className="container">
          <p>&copy; Copyright <span id="copyright-year"></span> Grant Harper</p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
