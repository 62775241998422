import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {generateBaseUrl} from '../../constants';


function Cook({match}) {
  const recipeId = match.params.id;
  const [recipe, setRecipe] = useState({})
  const [phase, setPhase] = useState('setup')
  const [phases, setPhases] = useState(['setup', 'gather', 'prep', 'cook'])

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get(
        `${generateBaseUrl()}/recipes/${recipeId}`
      );
      if (result.data) {
        setRecipe(result.data.recipe);
        if (!result.data.recipe.optimized.setup) {
          phases.shift()
          setPhases(phases);
          setPhase('gather');
        }
        if (!result.data.recipe.optimized.prep) {
          phases.splice(
            phases.findIndex((i) => i === 'prep'),
            1);
          setPhases(phases);
        }
      }
    }

    fetchData();
  }, [recipeId]);

  let text = '';
  if (recipe.optimized) {
    if (phase === 'setup') {
      text = (
        <React.Fragment>
          <h3>Setup</h3>
          <p>{recipe.optimized.setup}</p>
        </React.Fragment>
      );
    } else if (phase === 'gather') {
      text = recipe.optimized.gather.map((ing, i) => {
        return <p key={i}>{ing}</p>
      });
      text.unshift(<h3>Gather</h3>);
    } else if (phase === 'prep') {
      text = recipe.optimized.prep.map((p, i) => {
        return <p key={i}>{p}</p>
      });
      text.unshift(<h3>Prep</h3>);
    } else if (phase === 'cook') {
      text = recipe.optimized.steps.map((s, i) => <p key={i}>{s}</p>);
      text.unshift(<h3>Steps</h3>);
    }
  }

  const currentPhaseIndex = phases.findIndex((i) => i === phase);
  const nextValid = phases.length > currentPhaseIndex + 1
  const nextPhase = () => {
    if (nextValid) {
      setPhase(phases[currentPhaseIndex + 1]);
    }
  }
  const prevValid = currentPhaseIndex > 0;
  const prevPhase = () => {
    if (prevValid) {
      setPhase(phases[currentPhaseIndex - 1]);
    }
  }

  const prevControl = () => {
    if (prevValid) {
      return <button className="btn" onClick={prevPhase}>Previous</button>;
    } else {
      return <div style={{visibility: 'hidden'}}>Previous</div>;
    }
  }

  const cookControls = (
    <div className="cook-controls">
      {prevControl()}
      {nextValid && <button className="btn" onClick={nextPhase}>Next</button>}
    </div>
  )

  return recipe && (<div>
      <h1 className="recipe-title">{recipe.title}</h1>
      <div className="cook-container">
        {text}
      </div>
      {cookControls}
    </div>
  );
}

export default Cook;