import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {generateBaseUrl, IMAGE_BASE_URL} from '../../constants';
import {Link} from 'react-router-dom';

function Recipe({match}) {
  const recipeId = match.params.id;
  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get(
        `${generateBaseUrl()}/recipes/${recipeId}`
      );
      console.log(result);
      setData(result.data);
    }

    fetchData();
  }, [recipeId]);

  let recipe = <div></div>;
  if (data && data.recipe) {

    const ingredients = data.recipe.traditional.ingredients.map((ing, i) => <p key={i}>{ing}</p>);
    const instructions = <p>{data.recipe.traditional.instructions}</p>;

    const controls = (<div className="controls">
      <Link to={"/cook/" + recipeId}>
        <button className="btn btn-wide">Cook Now</button>
      </Link>
    </div>);

    recipe = (<div>
        <h1 className="recipe-title">{data.recipe.title}</h1>
        {controls}
        <div className="recipe-container">
          <div className="recipe-text">
            <div>
              <h2>Ingredients</h2>
              <div>{ingredients}</div>
            </div>
            <div>
              <h2>Instructions</h2>
              <div>{instructions}</div>
            </div>
          </div>
          <div>
            <div>
              <img className="recipe-large-image" src={`${IMAGE_BASE_URL}${data.recipe.image}`}/>
            </div>
          </div>
        </div>
        {controls}
      </div>
    );
  }
  return recipe;
}

export default Recipe;