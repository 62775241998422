import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {generateBaseUrl, IMAGE_BASE_URL} from '../../constants';
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


function Home() {
  const [recipes, setRecipes] = useState([])
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [hasMore, setHasMore] = useState(true)

  async function fetchData(currentRecipes, currentSkip, currentSearch) {
    const result = await axios.get(
      `${generateBaseUrl()}/recipes?limit=10&skip=${currentSkip}&search=${currentSearch}`
    );
    if (result && result.data && result.data.recipes) {
      const copiedRecipes = [...currentRecipes];
      const updatedRecipes = copiedRecipes.concat(result.data.recipes)
      setRecipes(updatedRecipes);
      const totalRecipes = result.data.total;
      setTotal(totalRecipes);
      console.log("recipe lengths", updatedRecipes.length, totalRecipes)
      if(updatedRecipes.length === totalRecipes) {
        setHasMore(false);
      }
    }
  }

  async function fetchFreshData(currentSearch) {
    const result = await axios.get(
      `${generateBaseUrl()}/recipes?limit=10&skip=0&search=${currentSearch}`
    );
    if (result && result.data && result.data.recipes) {
      const currentRecipes = result.data.recipes
      const totalRecipes = result.data.total;
      setRecipes(currentRecipes);
      setTotal(totalRecipes);
      console.log("recipes from search", currentRecipes, totalRecipes)
      if(currentRecipes.length === totalRecipes) {
        setHasMore(false);
      }
    }
  }

  useEffect(() => {
    fetchData(recipes, skip, search);
  }, [skip]);

  useEffect(() => {
    fetchFreshData(search);
  }, [search])

  return (<div>
      <h1>Public Recipes</h1>
      <div>
        <input placeholder="Search" type="text" className="input" name="search" onChange={(e) => {
          setHasMore(true)
          setSkip(0)
          setSearch(e.target.value)
        }} />
      </div>
      <InfiniteScroll
        dataLength={recipes.length} //This is important field to render the next data
        next={() => setSkip(skip + 10)}
        hasMore={hasMore}
        loader={<div style={{textAlign: 'center'}}>Loading...</div>}
        endMessage={
          <div style={{textAlign: 'center'}}>
            You've scrolled to the bottom. Way to go. Now click on something!
          </div>
        }
      >
        <div className="card-container">

          {recipes.map((item, i) => {
            return (
              <Link to={"/recipes/" + item.id} key={i}>
                <div className="card">
                  <p className="card-title">
                    {item.title}
                  </p>
                  <div className="card-body">
                    <img alt="" className="recipe-card-image" src={`${IMAGE_BASE_URL}${item.image}`}/>
                  </div>
                </div>
              </Link>
            );
          })}

        </div>
      </InfiniteScroll>
    </div>
  );
}

export default Home;